const adminDetail = (state = {}, action) => {
  switch (action.type) {
    case "adminDetail":
      return action.payload;
    default:
      return state;
  }
};

export default adminDetail;
