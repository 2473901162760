import React, { useState, useEffect } from "react";
import { Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LogInFooter from "../../logincomponent/LogInFooter";
import GeneralFilter from "../../component/GeneralFilter";
import { api } from "../../constants/Services";
import TopBar from "../../navbar/TopBar";
import eye_icon from "../../assets/Icons/Normal Icons/Eye.svg";
import delete_icon from "../../assets/Icons/Normal Icons/Delete.svg";
import edit_icon from "../../assets/Icons/Normal Icons/Edit.svg";
import SearchIcon from "../../assets/Icons/Normal Icons/Search.svg";
import TopHeader from "../../navbar/TopHeader";
import {
  admins_list_store,
  admins_roles_store,
  admin_detail,
} from "../../constants/constants/Storage/actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorModel from "../../component/errorModel";
import ReactPaginate from "react-paginate";

function DepartmentList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = localStorage.getItem("permission");
  const [modalError, setModalError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [activePages, setActivePages] = useState();
  const [count, setCount] = useState(0);
  const [name, setName] = useState("");
  const [btn, setBtn] = useState(false);
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState({});

  const get_admins_list = () => {
    let params;

    if (search !== "") {
      params = `?page=${page}&name=${search}`;
    } else {
      params = `?page=${pages}`;
    }

    api.departmentNewList(params).then((res) => {
      if (res?.status == "success") {
        setList(res?.departments);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setActivePages(res?.pagination);
        dispatch(admins_list_store(res?.admins));
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    if (search !== "") {
      setPage(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
    }
    setCount(selectedObject.selected);
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    get_admins_list();
    // get_roles();
  }, [pages, search, page]);

  const submitHandle = () => {
    let formdata = new FormData();
    formdata.append("name", name);

    setBtn(false);
    if (update?.id) {
      api
        .departmentUpdate(update?.id, formdata)
        .then((res) => {
          if (res?.status == "success") {
            setModalError(res);
            setShow(false);
            setErrorBox(true);
            get_admins_list();
            setName("");
            setUpdate({});
          } else {
            setModalError(res);
            setErrorBox(true);
          }

          setBtn(false);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    } else {
      api
        .departmentCreate(formdata)
        .then((res) => {
          if (res?.status == "success") {
            setModalError(res);
            setShow(false);
            setErrorBox(true);
            get_admins_list();
            setName("");
            setUpdate({});
          } else {
            setModalError(res);
            setErrorBox(true);
          }
          setBtn(false);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const handleEdit = (item) => {
    setUpdate(item);
    setName(item?.value);
    setShow(true);
  };

  return (
    <>
      {errorBox && (
        <ErrorModel
          error={modalError}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopBar />
      <TopHeader type="department-list" />
      <div className="w-90  py-3 my-2  mid-sc-res">
        <div className="d-flex  flex-wrap my-2">
          <Col xs={12} sm={12} md={11} className="px-4">
            <div className="d-flex flex-wrap my-2 my-md-0 align-items-center">
              <Col
                xs={12}
                lg={4}
                md={8}
                className="d-flex my-1 ms-auto my-md-0"
              >
                <div className="search-box d-flex justify-content-between align-items-center px-2 w-100 rounded-1">
                  <input
                    placeholder="Search Title"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img className="search-icon" src={SearchIcon} alt="searBar" />
                </div>
              </Col>
            </div>
          </Col>
          {/* {permissions?.includes("ADD-ADMINS") ? ( */}
          <Col xs={12} sm={12} md={1}>
            <button
              className="border-0 fs-14px fnt-fam-f2 p-2 bg-primary1 add_button text-white text-nowrap"
              onClick={() => setShow(true)}
            >
              Add Department
            </button>
          </Col>
          {/* ) : null} */}

          <div className="action-page-cont">
            <p>Showing</p>
            <div className="page-status-cont">
              <p
                style={{
                  border: "1px solid #ced4da",
                  // padding: "4px",
                  // margin: "10px 0px",
                  textAlign: "center",
                  width: "35px",
                  height: "28px",
                }}
              >
                {list?.length}
              </p>
            </div>
            <p>of {activePages?.total} Department</p>
          </div>
        </div>
        <Col style={{ minHeight: "70vh" }} className="prduct-page">
          <div className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll">
            <table className="w-100" style={{ minWidth: "750px" }}>
              <thead>
                <tr>
                  <th className="text-center fs-15px">S.No</th>
                  <th className="text-center fs-15px">Department</th>
                  <th className="text-center fs-15px">Action</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, ind) => (
                  <>
                    <tr key={ind} className="shipment-table">
                      <td className="text-center fs-14px">
                        {ind + 1 == 10 ? count + 1 : count}
                        {ind + 1 == 10 ? 0 : ind + 1}
                      </td>
                      <td className="text-center fs-14px">{item?.value}</td>
                      <td>
                        <div className="d-flex gap-3 action-box  justify-content-center pointer">
                          {/* {permissions.includes("EDIT-ADMINS") && ( */}
                          <img
                            src={edit_icon}
                            alt="edit-icon"
                            onClick={() => handleEdit(item)}
                          />
                          {/* )} */}

                          {/* {login?.permissions.includes(
                              "CAN-DELETE-ADMINS"
                            ) && <img src={delete_icon} alt="delete-icon" />} */}
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
        <div className="page-inner-cont py-2 d-flex justify-content-end">
          <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="‹"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>

      <Modal
        centered
        show={show}
        onHide={() => {
          setName("");
          setUpdate({});
          setShow(false);
        }}
        className="depart-design-input-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title" />
        </Modal.Header>
        <Modal.Body className="descrioption-input">
          {/* <h5>Add Department</h5> */}
          <h6 className="mt-2 mb-4">Department Name</h6>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Modal.Body>
        <div className="px-3 w-100">
          <button
            onClick={() => submitHandle()}
            className="cust-btn bg-primary1 py-2 text-white w-100 mb-5"
            disabled={btn ? true : false}
          >
            {update?.id ? "Edit" : "Add"} Department
          </button>
        </div>
      </Modal>
    </>
  );
}

export default DepartmentList;
