const new_supplier_list_show = (state = [], action) => {
  switch (action.type) {
    case "new_suppliers_list_store":
      return action.payload;
    default:
      return state;
  }
};

export default new_supplier_list_show;
