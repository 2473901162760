// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const TopHeader = (props) => {
  return (
    <div className="hede-top">
      {props.type === "admin-list" && (
        <div className="d-flex-al-jb">
          <h4>Admin User</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <AiOutlineHome />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <MdOutlineKeyboardArrowRight />
            <li>
              <Link to="/admin/list">Admin User</Link>
            </li>
          </ul>
        </div>
      )}

      {props.type === "department-list" && (
        <div className="d-flex-al-jb">
          <h4>Department List</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <AiOutlineHome />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <MdOutlineKeyboardArrowRight />
            <li>
              <Link to="/department/list">Department List</Link>
            </li>
          </ul>
        </div>
      )}

      {props.type == "broadcast" && (
        <div className="d-flex-al-jb">
          <h4>BroadCast</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/admin/broadcast/list">BroadCast</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}

      {props.type === "new-supplier" && (
        <div className="d-flex-al-jb">
          <h4>New Supplier</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/new-supplier">New Supplier</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}

      {props.type === "existing-supplier" && (
        <div className="d-flex-al-jb">
          <h4>Existing Supplier</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/existing-supplier">Existing Supplier</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type === "role" && (
        <div className="d-flex-al-jb">
          <h4>Role</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/roles">Role</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}

      {props.type === "blog" && (
        <div className="d-flex-al-jb">
          <h4>Blog</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/blogs">Blogs</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}

      {props.type == "dashboard" && (
        <div className="d-flex-al-jb">
          <h4 className="primary1">Dashboard</h4>
        </div>
      )}
      {props.type == "admin-dashboard" && (
        <div className="d-flex-al-jb">
          <h4 className="fnt-fam-f3">Dashboard</h4>
          <li>
            <AiOutlineHome />
          </li>
        </div>
      )}
      {props.type === "purchase-order" && (
        <div className="d-flex-al-jb">
          <h4>Purchase Orders</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/po-list">Purchase Orders</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type === "advance-shipping-notice" && (
        <div className="d-flex-al-jb">
          <h4>Advance Shipping Notice</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/asn-list">Advance Shipping Notice</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type === "delivery-challan" && (
        <div className="d-flex-al-jb">
          <h4>Delivery Challan</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/dc-list">Delivery Challan</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type === "goods-received-notes" && (
        <div className="d-flex-al-jb">
          <h4>Goods Received Notes</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/grn-list">Goods Received Notes</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type === "return-notes" && (
        <div className="d-flex-al-jb">
          <h4>Return Notes</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/rn-list">Return Notes</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      
      
      
    </div>
  );
};

export default TopHeader;
