import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import "./responsive.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./layout/Layout";
import Role from "./routes/Admin/Admin_Role/AdminRoles";
import AddRole from "./routes/Admin/Admin_Role/AddRole";
import AdminsList from "./routes/Admin/AdmimList";
import AdminAddForm from "./component/Admin/AdminAddForm";
import AdminDashboard from "./routes/Admin/Admin_Dashboard/Ad_dashboard";
import AdminCreateBroadCast from "./routes/Admin/Admin_Broadcast/Ad_CreateBroadcast";
import AdminBroadcastView from "./routes/Admin/Admin_Broadcast/Ad_Brocast_view";
import AdminBroadcastList from "./routes/Admin/Admin_Broadcast/Ad_BroadCast_List";
import AdminSettings from "./routes/Admin/Admin_settings/AdminSettings";
import AdminLogIn from "./component/Admin/AdminLogin/Ad_Login";
import AdminLogInForgot from "./component/Admin/AdminLogin/ForgotLogin";
import AdminPurchaseOrder from './component/Admin/AdminDashboard/PurchaseOrderList.js';
import AdminAdvanceShippingNotice from './component/Admin/AdminDashboard/AsnList.js';
import AdminDeliveryChallan from './component/Admin/AdminDashboard/DeliveryChallanList.js';
import AdminGoodsReceivedNote from './component/Admin/AdminDashboard/GoodsReceivedNoteList.js';
import AdminReturnNotes from './component/Admin/AdminDashboard/ReturnNotesList.js';

// import AdminDetails from "./routes/EntrerPrises/AdminDetails";
import ExistingSupplier from "./routes/Admin/Admin_Supplier/ExistingSupplier";
import NewSupplier from "./routes/Admin/Admin_Supplier/NewSupplier";
import ViewSupplier from "./routes/Admin/Admin_Supplier/ViewSupplier";
import Blogs from "./routes/Admin/Admin_Blogs/AdminBlogs";
import AdminBlogForm from "./component/Admin/AdminBlogForm";
import PrivateRoutes from "./constants/Services/PrivateRoutes";
import useToken from "./constants/constants/Storage/useToken";
import SetPassword from "./component/Admin/SetPassword";
import DepartmentList from "./routes/Department/DepartmentList";

const ReactRoutes = () => {
  const { token } = useToken();
  return (
    <Routes>
      {/* {naren start} */}
      <Route path="/" index element={<AdminLogIn />} />
      <Route path="/setpassword" index element={<SetPassword />} />
      <Route path="/forgotlogin" element={<AdminLogInForgot />} />
      <Route element={<PrivateRoutes token={token} />}>
        <Route path="/admin/LogIn" index element={<AdminLogIn />} />
        <Route path="/LogIn" index element={<AdminLogIn />} />
        <Route element={<Layout />}>
          <Route
            path="/admin/broadcast/create-broadcast"
            element={<AdminCreateBroadCast />}
          />
        </Route>

        <Route element={<Layout />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/admin/broadcast/view"
            element={<AdminBroadcastView />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/admin/broadcast/list"
            element={<AdminBroadcastList />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/roles" element={<Role />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/add-role" element={<AddRole />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/admin/list" element={<AdminsList />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/department/list" element={<DepartmentList />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/admin/add" element={<AdminAddForm />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/blogs" element={<Blogs />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/blog/add" element={<AdminBlogForm />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/existing-supplier" element={<ExistingSupplier />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/view-supplier" element={<ViewSupplier />} />
        </Route>

        <Route element={<Layout />}>
          <Route path="/new-supplier" element={<NewSupplier />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="admin/settings" element={<AdminSettings />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/po-list" element={<AdminPurchaseOrder />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/asn-list" element={<AdminAdvanceShippingNotice />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/dc-list" element={<AdminDeliveryChallan />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/grn-list" element={<AdminGoodsReceivedNote />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/rn-list" element={<AdminReturnNotes />} />
        </Route>
        {/* end naren */}
      </Route>
    </Routes>
  );
};
export default ReactRoutes;
