import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorModel from "../../../component/errorModel";
import { view_roles } from "../../../constants/constants/Storage/actions";
import { Col } from "react-bootstrap";
import { api } from "../../../constants/Services";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import { useNavigate, useLocation } from "react-router";

function AddRole() {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const viewRoles = useSelector((state) => state.viewRoles);
  const roleDetail = useSelector((state) => state.roleDetail);
  const location = useLocation();
  const navigate = useNavigate();
  const purpose = location?.state?.action;

  // console.log("loca-de", purpose);
  const [list, setList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState("");

  // console.log("indidual-role-detail", roleDetail);

  const roles_list = () => {
    let data = "?type=1";
    api.view_roles(data).then((res) => {
      if (res?.status === "success") {
        let other1 = [];
        let other2 = [];
        let temp = {};
        let id9 = res?.modules.filter((i) => i?.id == 1);
        id9?.map((item, ind) => {
          let perm = item?.permissions?.filter((i) => i?.id == 1);

          temp.code = item?.code;
          temp.created_at = item?.created_at;
          temp.id = item?.id;
          temp.module_type = item?.module_type;
          temp.permissions = perm;
          temp.status = item?.status;
          temp.type = item?.type;
          temp.updated_at = item?.updated_at;
        });

        other2.push(temp);

        let id9s = res?.modules.filter((i) => i?.id !== 1);
        id9s?.map((item, ind) => {
          other1.push(item);
        });

        let result = other2.concat(other1);

        setList(result);
        dispatch(view_roles(res?.modules));
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const roleAddHandler = () => {
    switch (purpose) {
      case "create":
        console.log("coming add role");
        let data = {
          role: role,
          permissions: permissions,
          type: 1,
        };

        if (permissions.length > 0 && role !== "") {
          api.add_role(data).then((res) => {
            // console.log("add-rol-per-1-res", res);
            if (res?.status === "success") {
              setError(res);
              setErrorBox(true);
              setTimeout(() => {
                navigate("/roles");
              }, 800);
            } else {
              setError(res);
              setErrorBox(true);
            }
          });
        }
        break;
      case "edit":
        // console.log("coming edit role");
        let editData = {
          role: role,
          permissions: permissions,
          _method: "PUT",
        };

        if (permissions.length > 0 && role !== "") {
          api.update_role(roleDetail?.role?.id, editData).then((res) => {
            console.log("edit-rol-per-1-res", res);
            if (res?.status === "success") {
              setError(res);
              setErrorBox(true);
              setTimeout(() => {
                navigate("/roles");
              }, 800);
            } else {
              setError(res);
              setErrorBox(true);
            }
          });
        }
        break;
      default:
    }
  };

  // const invitedSelHandler = (inv_id) => {
  //   if (invited.includes(inv_id)) {
  //     let filteredList = invited.filter((item) => item !== inv_id);
  //     setInvited(filteredList);
  //   } else {
  //     setInvited((invited) => [...invited, +inv_id]);
  //   }
  // };
  const permissionHandler = (per) => {
    if (permissions.includes(per)) {
      let filteredList = permissions.filter((item) => item !== per);
      setPermissions(filteredList);
    } else {
      setPermissions((permissions) => [...permissions, +per]);
    }
  };
  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };
  // console.log("permis", permissions);

  useEffect(() => {
    roles_list();
    if (purpose !== "create") {
      let temp = roleDetail?.permissions.map((item) => +item);
      setPermissions(temp);
      setRole(roleDetail?.role?.role);
    }
  }, []);
  // console.log("permision-view", permissions);

  return (
    <>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopBar />
      <TopHeader type="role" />
      <div className="w-90  px-2 py-3 my-2">
        <Col xs={12} md={6}>
          <h6>Role Title</h6>
          <div className="input-group my-3 cont-border">
            <input
              value={role}
              onChange={(e) => setRole(e.target.value)}
              type="text"
              className="form-control border-0  py-2 px-3"
              placeholder="Role Title"
              aria-label="Role Title"
              disabled={purpose === "view" ? true : ""}
            />
          </div>
        </Col>
        <div className="d-flex flex-wrap my-3 py-2">
          {list?.map((module) => (
            <Col key={module.id} xs={12} md={6} lg={4} className="my-1 py-1">
              <h5>{module?.module_type}</h5>
              <ul>
                {module?.permissions.map((permission) => (
                  <li className="my-1 py-1 d-flex gap-2" key={permission?.id}>
                    <input
                      onClick={() => permissionHandler(permission?.id)}
                      className="pointer"
                      type="checkbox"
                      checked={permissions?.includes(permission?.id)}
                      disabled={purpose === "view" ? true : ""}
                    />

                    <p className="mb-0"> {permission.code}</p>
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </div>
        {purpose == "view" ? null : (
          <div className="d-flex justify-content-center my-3">
            <button
              disabled={purpose === "view" ? true : ""}
              className="border-0 fs-14px fnt-fam-f2 px-3 py-2 bg-primary1 text-white"
              onClick={roleAddHandler}
            >
              {purpose === "edit" ? "Update" : "Add"} Role
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default AddRole;
