// @ts-nocheck
import React, { useState } from "react";
import { Button, Col, Form, Image, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import { Logo, FavLogo, Check, loaderIcon, LoginVideo } from "../assets/img";
import { Logo, showPwdImg, hidePwdImg,FavLogo, Check, loaderIcon, LoginVideo } from "../../../assets/img";
import LogInFooter from "../../../logincomponent/LogInFooter";
import LogInConnect from "../../../logincomponent/LogInConnect";
import OtpModal from "../../../logincomponent/OtpModal";
import { api } from "../../../constants/Services/";
import { EMAIL } from "../../../constants/Services/constants";
import ErrorModel from "../../errorModel";

const ForgotLogin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [OtpModal, setOtpModal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const submitHandle = () => {
    if (email?.length < 4) {
      setEmailErr(true);
    } else {
      setDisabledBtn(true);
      let data = {
        email: email,
      };
      api.forgotPassword(data).then((res) => {
        if (res?.status == "success") {
          // setOtpModal(true);
          setDisabledBtn(false);
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            // setEmailErr(false);
            navigate("/login");
          }, 3000);
        } else {
          setDisabledBtn(false);
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    // <></>
    <div className="login-bg">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <div className="video-bg-forgot">
        <video autoPlay loop muted className="">
          <source src={LoginVideo} type="video/mp4" />
        </video>
        <div className="d-flex-al-jc  log_cent">
          <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
            <div className="form_box">
              <Image src={Logo} />
              <h6>Forgot Password</h6>
              <p>Enter Registered Email ID for OTP</p>
              <Col xs={12} className="my-2 position-relative pb-2">
                <input
                  className="form-control"
                  type="email"
                  placeholder=" Enter Registered Email ID"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {validEmail.test(email) == false && emailErr && (
                  <p className="err-input pe-0">Enter Registered Email</p>
                )}
              </Col>
              <button
                disabled={disabledBtn && true}
                onClick={submitHandle}
                style={{ opacity: disabledBtn ? 0.3 : 1 }}
                className="cust-btn bg-primary1 text-white w-100 py-2 mt-2"
              >
                Request OTP
                {disabledBtn && (
                  <img src={loaderIcon} style={{ width: "19px" }} />
                )}
              </button>
              <div className="mt-3  ">
                <Modal
                  className="otp-modal"
                  show={OtpModal}
                  size="md"
                  centered
                  onHide={() => setOtpModal(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                >
                  <div className="sup-modal ">
                    <Image src={FavLogo} />
                    <div className="mt-4">
                      <div className="d-flex-as-jc img_tick ">
                        <Image src={Check} />
                        <div className="px-3">
                          <h5>OTP Sent</h5>
                          <p>OTP Sent to your registered E-mail ID</p>
                        </div>
                      </div>
                      <Button
                        style={{ background: "#247395" }}
                        className="my-3 modal_btn text-center m-auto d-flex"
                        onClick={() => navigate("/verifyotp", { state: email })}
                      >
                        OK
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
              <LogInConnect />
            </div>
          </Col>
        </div>
      </div>
      <LogInFooter />
    </div>
  );
};
export default ForgotLogin;
