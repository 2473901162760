import AdminBroadcastListButtonComponent from "../../../component/Admin/AdminBroadCast/Ad_Broadcast_list_Btn_com";
import AdminBroadcastListTableComponent from "../../../component/Admin/AdminBroadCast/Ad_Broadcast_table_com";
import GeneralShortPagination from "../../../component/GeneralShortPagination";
import React, { useEffect } from "react";
import TopHeader from "../../../navbar/TopHeader";
import TopBar from "../../../navbar/TopBar";
import LogInFooter from "../../../logincomponent/LogInFooter";
import { api } from "../../../constants/Services";
import { useDispatch } from "react-redux";

import {
  broadcast_list_data,
  create_broadcast_list_store,
} from "../../../constants/constants/Storage/actions";
function Ad_Broadcast_List() {
  const dispatch = useDispatch();

  useEffect(() => {
    api.get_broadcast_list().then((res) => {
      // console.log("broacast-list-res", res);
      dispatch(broadcast_list_data(res?.broadcasts));
    });
    api.create_broadcast().then((res) => {
      dispatch(create_broadcast_list_store(res));
    });
  }, []);

  return (
    <>
      <TopBar />
      <TopHeader type="broadcast" />
      <div style={{ minHeight: "80vh" }} className="m-3 p-3">
        <AdminBroadcastListButtonComponent />
        <AdminBroadcastListTableComponent />
        <GeneralShortPagination />
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default Ad_Broadcast_List;
