import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopHeader from "../../../navbar/TopHeader";
import { api } from "../../../constants/Services";
import SearchIcon from "../../../assets/Icons/Normal Icons/Search.svg";
import moment from "moment/moment";


function AdvanceShippingNoticeList() {
    const [grnList, setGrnList] = useState([]);
    const [counts, setCounts] = useState({});
    const [grnCount, setGrnCount] = useState(0);
    const [error, setError] = useState(null);
    const [errorBox, setErrorBox] = useState(false);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const isFirstLoad = useRef(true);


    const getList = (page, searchVal) => {
        let params = `?page=${page}&limit=20&search=${searchVal}`;
        setIsLoading(true);
        api.dashboardGrn(params).then((res) => {
            if (res?.status === "success") {
                setGrnList((prevList) => [...prevList, ...res?.goods_receipt_note]);
                setCounts(res);
                setGrnCount(res?.total_count);
                if (res?.goods_receipt_note.length < 20) {
                    setHasMore(false);
                }
            } else {
                setError(res);
                setErrorBox(true);
            }
        }).catch((err) => {
            console.error("err", err);
        }).finally(() => {
            setIsLoading(false);
        });
    };
  
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
    
        const newTimeout = setTimeout(() => {
            setGrnList([]); 
            setHasMore(true); 
            getList(1, value);
        }, 1000); 
        setDebounceTimeout(newTimeout);
    };
    
    useEffect(() => {
        if (!isFirstLoad.current) {
            getList(page, '');
        } else {
            isFirstLoad.current = false;
        }
    }, [page]); 
    
    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 100 &&
                !isLoading &&
                hasMore
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        };
    
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isLoading, hasMore]);


    return (
        <>
            <TopHeader type="goods-received-notes" />
            <div style={{ minHeight: "75vh" }} className="w-90 px-2 py-3 my-2">
                <Col xs={12} sm={12} md={12}>
                    <div className="d-flex flex-wrap my-2 my-md-0 align-items-center">
                        <Col xs={12} lg={4} md={8} className="d-flex my-1 ms-auto my-md-0">
                            <div className="search-box d-flex justify-content-between align-items-center px-2 w-100 rounded-1">
                                <input placeholder="Search By GRN No" value={search} onChange={handleSearchChange} />
                                <img className="search-icon" src={SearchIcon} alt="searBar" />
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col className="prduct-page">
                    <div
                        className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll "
                        style={{ overflowX: "auto" }}
                    >
                        <table className="w-100">
                            <thead>
                                <tr className="text-center">
                                    <th className="">S.No.</th>
                                    <th className="text-nowrap">KH Division</th>
                                    <th className="text-nowrap">GRN No.</th>
                                    <th className="text-nowrap">GRN Date</th>
                                    <th className="">DC Number</th>
                                    <th className="">Material Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {grnList?.map((item, ind) => {
                                       return (
                                        <tr className="text-center fs-13px fnt-fam-f1 dash-tr-hgt" key={ind}>
                                          <>
                                            <td>{ind + 1}</td>
                                            <td>{item?.factory?.FactoryName}</td>
                                            <td>
                                              {item?.LeatherGoodsReceiptNoteNo ||
                                                item?.MaterialGoodsReceiptNoteNo}
                                            </td>
                                            <td>
                                              {item?.LeatherGoodsReceiptNoteDate
                                                ? moment(item?.LeatherGoodsReceiptNoteDate).format(
                                                    "DD-MMM-YYYY"
                                                  )
                                                : item?.MaterialGoodsReceiptNoteDate
                                                ? moment(item?.MaterialGoodsReceiptNoteDate).format(
                                                    "DD-MMM-YYYY"
                                                  )
                                                : "-"}
                                            </td>
                                            <td>{item?.DCNo}</td>
                                            <td>
                                              {item?.type == "M"
                                                ? "Material"
                                                : item?.type == "L"
                                                ? "Leather"
                                                : "-"}
                                            </td>
                                          </>
                                        </tr>
                                      );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </div>
            <div className="rg-footer">
                <LogInFooter />
            </div>

        </>
    );
}

export default AdvanceShippingNoticeList;
