import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

const LogInConnect = () => {
  return (
    <div className="con_with">
      <p>
        Don't have an Account?{" "}
        <Link to="/register" className="text-blue ">
          Register Now
        </Link>
      </p>
      {/* <div className="social_media">
        <h5>Connect with us</h5>
        <Link to="#">
          <FaFacebookF />
        </Link>
        <Link to="#">
          <FaTwitter />
        </Link>
        <Link to="#">
          <FaYoutube />
        </Link>
        <Link to="#">
          <FaInstagram />
        </Link>
        <Link to="#">
          <FaLinkedinIn />
        </Link>
      </div> */}

      <Link to="/" className="text-blue ">
        <u>Home</u>
      </Link>
    </div>
  );
};
export default LogInConnect;
