import React from "react";
import "../Admin_view.scss";
import TopHeader from "../../../navbar/TopHeader";
import TopBar from "../../../navbar/TopBar";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";
import LogInFooter from "../../../logincomponent/LogInFooter";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import DOMPurify from "dompurify";

function AdminBroadcastView() {
  const purify = DOMPurify(window);
  const broacast_detail = useSelector((state) => state.broadcastDetail_show);
  let broadcast_list = useSelector((state) => state.broadcast_list_show);
  broadcast_list = broadcast_list.slice(
    Math.max(broadcast_list?.length - 5, 0)
  );
  // console.log("broaca-list", broadcast_list);
  // console.log("bracd_detail", broacast_detail);

  return (
    <>
      <TopBar />
      <TopHeader type="broadcast" />
      <div
        style={{ minHeight: "77vh" }}
        className="m-3 d-md-flex p-3 flex-wrap">
        <Col lg={8} md={12} sm={12} className="bor-right px-3">
          <div className="cont-border pt-3 pb-1 my-2">
            <p className="text-center">
              Lorem ipsum freestar freestar Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </p>
            <div className="d-sm-flex justify-content-center">
              <div className="bor-right py-2 px-4 my-3 me-2">
                <h4 className="primary1 text-center">
                  {broacast_detail?.suppliers?.length}
                </h4>
                <h6 className="opac_half fnt-fam-f2 text-center">
                  Targets Companies
                </h6>
              </div>
              <div className="bor-right py-2 px-4 my-3 me-2">
                <h4 className="primary1 text-center">4500</h4>
                <h6 className="opac_half fnt-fam-f2 text-center">
                  Targets Users
                </h6>
              </div>
              <div className="py-2 px-4 my-3">
                <h4 className="primary1 text-center">589</h4>
                <h6 className="opac_half fnt-fam-f2 text-center">
                  Total Views
                </h6>
              </div>
            </div>
          </div>
          <div className="border-btm py-1 my-2">
            <h5 className="fw-bold fs-15px fnt-fam-f2">
              Largest Independent Oil and Gas Company Since Anadarko? You
              Guessed it! it's OGTW219
            </h5>
            <p className="py-2 fs-14px">
              Published on :{" "}
              {moment(broacast_detail?.broadcast?.updated_at).format("LLL")}
            </p>
          </div>
          <h5 className="fs-15px opac-85">Message</h5>
          <p className="py-2 my-2">
            Future Prices As you can see from Figure 1, a long period of oil
            price stability was interrupted in 1973. In fact, the 1970s show two
            distinct jumps in oil prices: one was triggered by the Yom Kippur
            War in 1973, and one was prompted by the Iranian Revolution of 1979.
            Since then, oil prices have regularly displayed volatility relative
            to the ’50s and ’60s sport prices.
          </p>
          <p className="py-2 my-2">
            "Backwardation" and Courage In fact, the 1970s show two distinct
            jumps in oil prices: one was triggered by the Yom Kippur War in
            1973, and one was prompted by the Iranian Revolution of 1979. Since
            then, oil prices have regularly displayed volatility relative to the
            ’50s and ’60s sport prices.
          </p>
          <div className="cont-border rounded-1 p-2 d-flex justify-content-between align-items-center ad_bc_view_pdf_cont my-4">
            <div className="d-flex gap-1">
              <div className="d-flex align-items-center pdf-icon-cont">
                <AiOutlineFilePdf />
              </div>
              <p className="d-flex flex-column fs-13px align-items-center">
                PDF-1<span className="d-block opac_half fs-13px">25.5 KB</span>
              </p>
            </div>
            <FiDownload className="fs-1r opac_half" />
          </div>
        </Col>
        <Col lg={3} md={8} sm={12} className="py-2 my-3 px-3 ms-2">
          <div className="d-sm-flex justify-content-between mb-2">
            <h5 className="fnt-fam-f3 fs-1r">Latest BroadCast</h5>
            <Link to="/admin/broadcast/list">
              <h5 className="fnt-fam-f3 primary1 fs-1r pointer">View All</h5>
            </Link>
          </div>
          {broadcast_list?.map((list) => (
            <div key={list?.id} className="my-1 py-1 border-btm">
              <h5 className="fnt-fam-f2 fs-15px primary2 my-1 py-1">
                {list?.title}
              </h5>
              <h5 className="fnt-fam-f3 fs-15px my-1 py-1">
                Architecto beatae vitae
              </h5>

              <p
                className="fs-14px my-1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(list?.description),
                }}></p>
              <p className="opac_half fs-13px my-1">
                {moment(list?.updated_at).format("ll")}
              </p>
            </div>
          ))}
        </Col>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}
export default AdminBroadcastView;
