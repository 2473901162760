import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopHeader from "../../../navbar/TopHeader";
import { api } from "../../../constants/Services";
import SearchIcon from "../../../assets/Icons/Normal Icons/Search.svg";
import moment from "moment/moment";

function PurchaseOrderList() {
    const [list, setList] = useState([]);
    const [counts, setCounts] = useState({});
    const [poCount, setPoCount] = useState(0);
    const [error, setError] = useState(null);
    const [errorBox, setErrorBox] = useState(false);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const isFirstLoad = useRef(true);
    
    const getList = (page, searchVal) => {
        setIsLoading(true);
        let params = `?page=${page}&limit=20&search=${searchVal}`;
        api.dashboard(params).then((res) => {
            if (res?.status === "success") {
                setIsLoading(false);
                setList((prevList) => [...prevList, ...res?.purchase_orders_dashboard]);
                setCounts(res);
                setPoCount(res?.total_count);
                if (res?.purchase_orders_dashboard.length < 20) {
                    setHasMore(false);
                }
            } else {
                setIsLoading(false);
                setError(res);
                setErrorBox(true);
            }
        }).catch((err) => {
            console.error("err", err);
        })
        // .finally(() => {
        //     setIsLoading(false);
        // });
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
    
        const newTimeout = setTimeout(() => {
            setList([]); 
            setHasMore(true); 
            getList(1, value);
        }, 1000); 
        setDebounceTimeout(newTimeout);
    };
    
    useEffect(() => {
        if (!isFirstLoad.current) {
            getList(page, '');
        } else {
            isFirstLoad.current = false;
        }
    }, [page]); 
    
    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 100 &&
                !isLoading &&
                hasMore
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        };
    
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isLoading, hasMore]);
    

    return (
        <>
            <TopHeader type="purchase-order" />
            <div style={{ minHeight: "75vh" }} className="w-90 px-2 py-3 my-2">
                <Col xs={12} sm={12} md={12}>
                    <div className="d-flex flex-wrap my-2 my-md-0 align-items-center">
                        <Col xs={12} lg={4} md={8} className="d-flex my-1 ms-auto my-md-0">
                            <div className="search-box d-flex justify-content-between align-items-center px-2 w-100 rounded-1">
                                <input placeholder="Search By PO ID" value={search} onChange={handleSearchChange} />
                                <img className="search-icon" src={SearchIcon} alt="searBar" />
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col className="prduct-page">
                    <div
                        className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll "
                        style={{ overflowX: "auto" }}
                    >
                        <table className="w-100">
                            <thead>
                                <tr className="text-center">
                                    <th className="">S.No.</th>
                                    <th className="text-nowrap">KH Division</th>
                                    <th className="text-nowrap">PO ID</th>
                                    <th className="text-nowrap">PO.Ref.No</th>
                                    <th className="">Supplier Name</th>
                                    <th className="">Date</th>
                                    <th className="">Material Type</th>
                                    <th className="">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, ind) => {
                                        //   let factoriesName = factoriesList?.find(
                                        //     (i) => i?.id === +item?.FactoryList
                                        //   );
                                        return (
                                            <>
                                                <tr key={ind} className="shipment-table text-center">
                                                    <td>{ind + 1}</td>
                                                    <td>{item?.factory?.FactoryName}</td>
                                                    <td>
                                                        {item?.LeatherPurchaseOrderID ||
                                                            item?.MaterialPurchaseOrderID}
                                                    </td>
                                                    <td>
                                                        {item?.LeatherPurchaseOrderNo ||
                                                            item?.MaterialPurchaseOrderNo}
                                                    </td>
                                                    <td>{item?.supplier?.SupplierName}</td>
                                                    <td>
                                                        {item?.LeatherPurchaseOrderDate
                                                            ? moment(item?.LeatherPurchaseOrderDate).format(
                                                                "DD-MMM-YYYY"
                                                            )
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        {item?.type == "L"
                                                            ? "Leather"
                                                            : item?.type == "M"
                                                                ? "Material"
                                                                : "-"}
                                                    </td>
                                                    <td>
                                                        {item?.Approval == "A"
                                                            ? "Approved"
                                                            : item?.Approval == "C"
                                                                ? "Cancelled"
                                                                : "-"}
                                                    </td>

                                                </tr>
                                            </>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </div>
            <div className="rg-footer">
                <LogInFooter />
            </div>

        </>
    );
}

export default PurchaseOrderList;
