// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Form, Image, Button, Modal } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Logo,
  showPwdImg,
  hidePwdImg,
  FavLogo,
  Check,
  LoginVideo,
} from "../../assets/img/index";
import { api } from "../../constants/Services";
import ErrorModel from "../errorModel";
import LogInFooter from "../../logincomponent/LogInFooter";

const SetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const emailId = location?.state?.data?.user?.SPUD_email;
  const userName = location?.state?.data?.user?.SPUD_UserName;
  // console.log(location);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isConPwd, setIsConPwd] = useState(false);
  const [cpwd, setCpwd] = useState("");
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [emails, setEmails] = useState("");
  const [pwd, setPwd] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState(false);
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [btn, setBtn] = useState(false);
  const [RegSuccessModal, setRegSuccessModal] = useState(false);
  const [PwdSuccessModal, setPwdSuccessModal] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const tokenData = searchParams?.get("token");

  const tokenType = searchParams?.get("type");

  const [errorMessage, setErrorMessage] = useState("");

  const tokenVerificate = () => {
    api.tokenVerify(tokenData).then((res) => {
      if (res?.status == "success") {
        setList(res?.admin);
        setList2(res?.ad_user);
        setName(res?.admin?.email);
        setName2(res?.ad_user?.user_name);
        setName3(res?.admin?.email);
        // setEmails(res?.sup_user?.CompanyEmail);
        setError(res);
        setErrorBox(true);
      } else {
        setError(res);
        setErrorBox(true);
        setTimeout(() => {
          navigate("/register");
        }, 1500);
      }
    });
  };


  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPwd(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPwd(new_pass);
    }
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    if (tokenData) {
      tokenVerificate();
    }
  }, []);

  const submitHandle = () => {
    if (pwd?.length == 0 || cpwd?.length < 8) {
      setPassErr(true);
      setConfirmPassErr(true);
    } else if (errorMessage?.length == 0) {
      let forGot = {
        user_name: name2,
        password: pwd,
        password_confirmation: cpwd,
        token: tokenData,
      };
      if (tokenType == "forgot-pasword") {
        api.setPass(forGot).then((res) => {
          if (res?.status == "success") {
            // acceptReject();
            setError(res);
            setErrorBox(true);
            setPwdSuccessModal(true);
          } else {
            setError(res);
            setErrorBox(true);
          }
        });
      } else {
        if (list2 !== null) {
          let supdata = {
            user_name: name2,
            password: pwd,
            password_confirmation: cpwd,
            token: tokenData,
          };

          api.setPass(supdata).then((res) => {
            if (res?.status == "success") {
              // acceptReject();
              setError(res);
              setErrorBox(true);
              setPwdSuccessModal(true);
            } else {
              setError(res);
              setErrorBox(true);
            }
          });
        } else {
          let data = {
            first_name: list?.first_name,
            last_name: list?.last_name,
            mobile: list?.phone,
            user_name: name,
            password: pwd,
            password_confirmation: cpwd,
            email: list?.email,
            token: tokenData,
          };
          api.setPass(data).then((res) => {
            if (res?.status == "success") {
              // acceptReject();
              setError(res);
              setErrorBox(true);
              setRegSuccessModal(true);
            } else {
              setError(res);
              setErrorBox(true);
            }
          });
        }
      }
    }
    
    // else {
    //   setBtn(true);
    //   let formdata = new FormData();
    //   formdata.append("password", pwd);
    //   formdata.append("password_confirmation", cpwd);
    //   formdata.append("token", tokenData);
    //   api.setPassword(formdata).then((res) => {
    //     setBtn(false);
    //     if (res?.status == "success") {
    //       setRegSuccessModal(true);
    //     } else {
    //       setError(res);
    //       setErrorBox(true);
    //     }
    //   });
    // }
  };

  return (
    <div className="login-bg">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <div className="video-bg-setpass">
        <video autoPlay loop muted className="">
          <source src={LoginVideo} type="video/mp4" />
        </video>
        <div className="d-flex-al-jc log_cent">
          <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
            <div className="form_box">
              <Image src={Logo} />
              <h6>Welcome to KH Admin</h6>
              <Col
                xs={12}
                className="bg-transparent my-2 mt-3 position-relative pwd-container"
              >
                <input
                  name="pwd"
                  className="form-control"
                  placeholder="Enter Password"
                  type={isRevealPwd ? "text" : "password"}
                  value={pwd}
                  onChange={handlePassword}
                />
                <Image
                  type={isRevealPwd ? "text" : "password"}
                  src={isRevealPwd ? showPwdImg : hidePwdImg}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
                {pwd?.length == 0 && passErr && (
                  <span className="err-input">Enter Password</span>
                )}
                {errorMessage && (
                  <p className="err-input mt-5" style={{ bottom: "-33px" }}>
                    {errorMessage}
                  </p>
                )}
              </Col>
              <Col
                xs={12}
                className="bg-transparent my-2 mt-3 position-relative pwd-container"
              >
                <input
                  name="pwd"
                  className="form-control"
                  placeholder="Confirm Password"
                  type={isConPwd ? "text" : "password"}
                  value={cpwd}
                  onChange={(e) => setCpwd(e.target.value)}
                />
                <Image
                  title={isConPwd ? "Hide password" : "Show password"}
                  src={isConPwd ? showPwdImg : hidePwdImg}
                  onClick={() => setIsConPwd((prevState) => !prevState)}
                />
                {cpwd?.length == 0 && confirmPassErr && (
                  <span className="err-input">Enter Confirm Password</span>
                )}
                {pwd !== cpwd && cpwd?.length !== 0 && (
                  <p className="err-input" style={{ bottom: "-33px" }}>
                    Password mismatch
                  </p>
                )}
              </Col>
              <button
                style={{ background: "#247395" }}
                onClick={submitHandle}
                className="bt-tx"
                disabled={btn ? true : false}
              >
                Set Password
              </button>
            </div>
          </Col>
          <div className="mt-3  ">
            <Modal
              id="#sucess"
              className="otp-modal"
              show={RegSuccessModal}
              size="md"
              centered
              onHide={() => setRegSuccessModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="sup-modal ">
                <Image className="fav-icon" src={FavLogo} />
                <div className="mt-0">
                  <div className="d-flex-as-jc img_tick ">
                    <Image src={Check} />
                    <div className="px-3">
                      <h5>Registration Successfully</h5>
                      <p>You can now login to your KH Admin Account</p>
                    </div>
                  </div>
                  <Link
                    className="my-3 cust-btn mx-auto d-flex bg-primary1 text-white py-2 text-center"
                    style={{ width: "63px" }}
                    to={"/"}
                  >
                    Login
                  </Link>
                </div>
              </div>
            </Modal>

            <Modal
              id="#sucess"
              className="otp-modal"
              show={PwdSuccessModal}
              size="md"
              centered
              onHide={() => setPwdSuccessModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="sup-modal ">
                <Image className="fav-icon" src={FavLogo} />
                <div className="mt-0">
                  <div className="d-flex-as-jc img_tick ">
                    <Image src={Check} />
                    <div className="px-3">
                      <h5>Password was reset successfully</h5>
                      <p>You can now login to your KH Admin Account</p>
                    </div>
                  </div>
                  <Link
                    className="my-3 cust-btn mx-auto d-flex bg-primary1 text-white py-2 text-center"
                    style={{ width: "63px" }}
                    to={"/"}
                  >
                    Login
                  </Link>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <LogInFooter />
    </div>
  );
};
export default SetPassword;
