import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";
import LogInFooter from "../../logincomponent/LogInFooter";
import { useNavigate, useLocation } from "react-router-dom";
import JoditEditor from "jodit-react";

function AdminBlogForm() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const display = location?.state?.action;
  const [image, setImage] = useState();
  const [imagePre, setImagePre] = useState();

  const blogActionHandler = () => {
    navigate("admin/list");
  };
  return (
    <>
      <TopBar />
      <TopHeader type="blog" />
      <Col
        style={{ minHeight: "77vh" }}
        xxl={7}
        lg={8}
        md={10}
        sm={11}
        xs={12}
        className="my-4 mx-auto p-3">
        <div className="my-4">
          <h4>Add Blog</h4>
        </div>
        <Row className="my-2">
          <Col sm={6}>
            <h6>Blog Title</h6>
            <div className="input-group my-3 cont-border">
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className="form-control border-0 py-2 px-3"
                placeholder="Blog Title"
                aria-label="Blog Title"
              />
            </div>
          </Col>
          <Col sm={6}>
            <h6>Upload Blog Image</h6>
            <div class="file-input mb-3 mt-2 cont-border w-100">
              <input type="file" />
              <span class="button">Choose</span>
              <span class="label" data-js-label>
                No file selected
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={12}>
            <Col sm={12}>
              <h6>Description</h6>
              <JoditEditor className="my-3" />
            </Col>
          </Col>
        </Row>
        <Col xs={12}>
          <div className="my-4 d-flex justify-content-center justify-content-md-end">
            <button
              className="border-0 fs-14px fnt-fam-f2 px-3 py-2 bg-primary1 text-white"
              onClick={blogActionHandler}>
              {display === "edit" ? "Edit" : "Add"} Blog
            </button>
          </div>
        </Col>
      </Col>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default AdminBlogForm;
