import { BsPlus } from "react-icons/bs";
import { BsSliders } from "react-icons/bs";
import Filter_icon from "../../../assets/Icons/Normal Icons/Filter.svg";
import { Link } from "react-router-dom";
import React from "react";

function Admin_Broadcast_list_Button_component() {
  return (
    <div className="d-sm-flex justify-content-between flex-wrap">
      <h5 className="fnt-fam-f2 fs-15px primary1">BroadCast</h5>
      <div className="d-sm-flex gap-3">
        <button className="bg-primary2 border-0 fnt-fam-f2 text-white fs-14px ad-bc-list-btn">
          <img src={Filter_icon} className="wid-15px me-1" alt="filterimage" />
          Hide Filter
        </button>
        <Link
          to="/admin/broadcast/create-broadcast"
          className="bg-primary1 border-0 fnt-fam-f2 text-white fs-14px ad-bc-list-btn">
          <BsPlus />
          Create New
        </Link>
      </div>
    </div>
  );
}

export default Admin_Broadcast_list_Button_component;
