// export const BASE_URL = "http://192.168.1.28:8000/";
// https://sapidemo.khexports.in/public/index.php
// export const BASE_URL = "http://192.168.1.39/laravel-juara/public/"; // sujith sir
// export const BASE_URL = "http://192.168.1.9/laravel-juara/public/";
export const BASE_URL = "https://sapidemo.khexports.in/public/index.php/"; // live
// export const BASE_URL = "https://sapidemo.khexports.in/index.php/";
// export const BASE_URL = "http://localhost:8000/index.php/"
// export const BASE_URL = "http://192.168.1.19/laravel-juara/public/"; // Kowshika
// http://192.168.1.26:8000/api

export const URL = {
  // LOGIN
  LOGIN: "api/admin/login",
  LOGOUT: "api/admin/logout",
  FORGOT_PASSWORD: "api/admin/forgot-password",

  SETPASSWORD: "api/admin/set-password",
  SET_PASS: "api/admin/password-set",

  TOKEN_VERIFY: "api/admin/admin-token-validate/",


  // DEPARTMENT
  DEPARTMENT: "supplierapi/supplier-master",
  DEPARTMENT_LIST: "supplierapi/supplier-user/create",
  DEPARTMENT_NEW_LIST: "api/departments",
  DEPARTMENT_EDIT: "api/department/",
  DEPARTMENT_ADD: "api/create/department",

  // DASHBOARD

  DASHBOARD: "api/dashboard-admin-po",

  DASHBOARDLIST: "api/admin/dashboard-admin-po-list",


  DASHBORD_ASN: "api/dashboard-admin-asn-list",

  DASHBORD_GRN: "api/dashboard-admin-grn-list",

  DASHBORD_DC: "api/dashboard-admin-dc-list",

  DASHBOARD_RN: "api/dashboard-admin-return-notes",

  DASHBOARD_INTRASIT: "api/dashboard-admin-on-in-transit",

  DASHBOARD_ON_DELIVERED: "api/dashboard-admin-on-delivered",

  DASHBOARD_ON_GRN: "api/dashboard-admin-on-grn-osd",

  DASHBOARD_ON_RETURNS: "api/dashboard-admin-on-returns",

  DASHBORD_UPCOMING_LIST: "api/dashboard-admin-up-coming-delivers",

  DASHBORD_AMOUNT: "api/admin/dashboard",

  // IN FRONTEND
  SUPPLIER_PURCHASE: "supplierapi/supplier-purchase-orders",

  // ROLES
  ROLE: "api/role",
  CREATE_ROLE: "api/role/create",
  ROLE_ADD: "api/role",
  ROLE_EDIT: "api/role/",
  ROLE_STATUS: "api/role/status/",
  ROLE_ALL: "api/roles-all",

  // ADMIN
  ADMIN: "api/admin",
  ADMIN_ROLES: "api/active-roles",
  UPDATE_ADMIN: "api/admin/",
  ADMIN_STATUS: "api/admin/status/",

  // SUPPLIER

  NEW_SUPPLIER_LIST: "api/supplier-request-list",
  FACTORIES_LIST: "api/factories",
  SUPPLIER_STATUS: "api/supplier-status",

  // EXISTING SUPPLIER

  EXISTING_SUPPLIER_LIST: "api/admin/existing-suppliers",
  EXISTING_SUPPLIER_VIEW: "api/admin/existing-suppliers/",
  INVITE_EXISTING_SUPPLIER: "api/admin/existing-suppliers/create-users",
  EXISTING_SUPPLIER_FACTORY_CHANGE: "api/supplier-update",

  // TYPES

  TYPES: "supplierapi/create/suppliers",

  // BROADCAST
  CREATE_BROADCAST: "api/broadcast/create",
  BROADCAST_LIST: "api/broadcast",
  VIEW_BROADCAST: "api/broadcast/",
  FILTER_ALL: "api/broadcast?select_all=1",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const EMAIL_VALIDATE =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_NO = /^[6-9]{1}[0-9]{9}$/;

export const NUMBERS_ONLY = /^[0-9\b]+$/;
export const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");
export const validMobilNo = new RegExp("^[6-9]{1}[0-9]{9}$");
export const validText = new RegExp("^[a-zA-Z ]*$");
export const validAadharNumber = new RegExp("^[0-9]{12}$");
