import React, { useState, useEffect } from "react";
import GeneralFilter from "../../../component/GeneralFilter";
import GeneralPagination from "../../../component/GeneralPagination";
import { useNavigate } from "react-router-dom";
import eye_icon from "../../../assets/Icons/Normal Icons/Eye.svg";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import { api } from "../../../constants/Services";
import { Col } from "react-bootstrap";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  new_suppliers_list_store,
  factories_list_store,
} from "../../../constants/constants/Storage/actions";
import ErrorModel from "../../../component/errorModel";
import ReactPaginate from "react-paginate";
import LogInFooter from "../../../logincomponent/LogInFooter";

function NewSupplier() {
  const permissions = localStorage.getItem("permission");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [status, setStatus] = useState();
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const new_suppliers_list = useSelector(
    (state) => state.new_supplier_list_show
  );

  const [modalError, setModalError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [activePages, setActivePages] = useState();
  const [count, setCount] = useState(0);

  // console.log("new_suppler_list", new_suppliers_list);

  const get_suppliers = () => {
    let params;

    if (search !== "") {
      params = `?page=${page}&supplier_name=${search}`;
    } else {
      params = `?page=${pages}`;
    }

    api.get_new_suppliers(params).then((res) => {
      if (res?.status == "success") {
        setList(res?.suppliers);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setActivePages(res?.pagination);
      } else {
        setModalError(res);
        setErrorBox(true);
      }
      // console.log("new-suppliers", res);
      dispatch(new_suppliers_list_store(res?.suppliers));
    });
  };

  const handlePageChange = (selectedObject) => {
    if (search !== "") {
      setPage(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
    }

    setCount(selectedObject.selected);
  };

  const get_factores = () => {
    api.get_factories().then((res) => {
      dispatch(factories_list_store(res?.factories));
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    get_suppliers();
  }, [pages, search]);

  useEffect(() => {
    get_factores();
  }, []);

  const supplierStatusHandler = () => {
    setShow(!show);
    let supplier_data = {
      id: id || "",
      status: status || "",
      factories: checked || [],
    };
    api.new_supplier_status_change(supplier_data).then((res) => {
      if (res?.status === "success") {
        setId("");
        setStatus("");
        setChecked([]);
      }
    });
  };

  const viewNewSupplier = (view_id) =>
    navigate("/view-supplier", { state: { id: view_id } });

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={modalError}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="new-supplier" />
      <div className="w-90 px-2 py-3 my-2">
        <div className="d-flex flex-wrap my-2 my-md-0 align-items-center">
          <div className="action-page-cont">
            <p>Showing</p>
            <div className="page-status-cont">
              <p
                style={{
                  border: "1px solid #ced4da",
                  padding: "4px",
                  // margin: "10px 0px",
                  textAlign: "center",
                  width: "35px",
                  height: "28px",
                }}
              >
                {list?.length}
              </p>
            </div>
            <p>of {activePages?.total} Suppliers</p>
          </div>
          <Col xs={12} lg={4} md={8} className="d-flex my-1 ms-auto my-md-0">
            <div className="search-box d-flex justify-content-between align-items-center px-2 w-100 rounded-1">
              <input
                placeholder="Search Title"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <img className="search-icon" src={Search} alt="searBar" />
            </div>
          </Col>
        </div>
        <Col className="prduct-page min-ht-80">
          <div className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll">
            <table className="w-100" style={{ minWidth: "750px" }}>
              <thead>
                <tr>
                  <th className="text-center fs-15px">S.No</th>
                  <th className="text-center fs-15px">Supplier Name</th>
                  <th className="text-center fs-15px">Email</th>
                  <th className="text-center fs-15px">Mobile</th>
                  <th className="">GST No.</th>
                  <th className="text-nowrap">MSME Code/IE Code</th>
                  {permissions?.includes("VIEW-NEW SUPPLIER") && (
                    <th className="text-center fs-15px">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {list?.map((item, ind) => (
                  <>
                    <tr
                      key={item?.id}
                      className="shipment-table fs-14px fnt-fam-f1"
                    >
                      <td className="text-center">
                        {ind + 1 == 10 ? count + 1 : count}
                        {ind + 1 == 10 ? 0 : ind + 1}
                      </td>
                      <td className="text-center">{item?.supplier_name}</td>
                      <td className="text-center">{item?.primary_email}</td>
                      <td className="text-center">{item?.phone}</td>
                      <td className="text-center">{item?.gst_no ? item?.gst_no == "null" ? "-" : item?.gst_no : "-"}</td>
                      <td className="text-center">{item?.msme_no ? item?.msme_no == "null" ? "-" : item?.msme_no : "-"}</td>
                      {permissions?.includes("VIEW-NEW SUPPLIER") && (
                        <td className="text-center pointer img_tick">
                          <img
                            src={eye_icon}
                            alt="eye-icon"
                            onClick={() =>
                              navigate("/view-supplier", {
                                state: { data: item },
                              })
                            }
                          />
                        </td>
                      )}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
        <div className="page-inner-cont d-flex justify-content-end">
          <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="‹"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>

      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default NewSupplier;
