import React, { useState } from "react";
// import SideBar from "../navbar/SideBar";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsBroadcastPin } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBlog,
  faGauge,
  faArrowDown,
  faUsers,
  faUserShield,
  faMoneyBill,
  faNoteSticky,
  faDrawPolygon,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";

const Navbar = ({ outletWidth, setOutletWidth }) => {
  const [linkwidth, setLinkwidth] = useState(false);
  const [dropArrow, setDropArrow] = useState(false);
  const [currentSubmenu, setCurrentSubMenu] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const login = useSelector((state) => state.login_detail);
  // let permissions = login?.permissions.map((user_access) => {
  //   let temp = user_access.split("-");
  //   return temp[2].toLowerCase();
  // });

  // permissions = [...new Set(permissions)];

  const permissions = localStorage.getItem("permission");
  // console.log("login", login);
  // console.log("permissions", permissions);
  const splitLocations = pathname.split("/");

  const onClickSubmenuHandler = (currentMenu) => {
    currentSubmenu === currentMenu
      ? setCurrentSubMenu("")
      : setCurrentSubMenu(currentMenu);
  };
  const onClickExpandHandler = () => {
    setLinkwidth(!linkwidth);
    setDropArrow(false);
    setOutletWidth(!outletWidth);
  };

  return (
    <div className="nav-bar">
      <nav>
        <ul
          style={{
            width: linkwidth ? "210px" : "47px",
          }}
        >
          {permissions?.includes("LIST-DASHBOARD") && (
            <li className="nav-li">
              <button
                className={
                  currentSubmenu === "dashboard"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
                style={{ width: "47px" }}
              >
                <FontAwesomeIcon icon={faGauge} />
              </button>
              {linkwidth && (
                <Link
                  type="dashboard"
                  onClick={() => onClickSubmenuHandler("dashboard")}
                  className={
                    currentSubmenu === "dashboard"
                      ? "nav-link active"
                      : "nav-link "
                  }
                  to="admin/dashboard"
                >
                  Dashboard
                </Link>
              )}
            </li>
          )}
          {permissions?.includes("LIST-ADMINS") && (
            <li className="nav-li">
              <button
                className={
                  currentSubmenu === "admin"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
                style={{ width: "47px" }}
              >
                <FontAwesomeIcon icon={faUser} />
              </button>
              {linkwidth && (
                <Link
                  type="admin"
                  onClick={() => onClickSubmenuHandler("admin")}
                  className={
                    currentSubmenu === "admin" ? "nav-link active" : "nav-link "
                  }
                  to="admin/list"
                >
                  Users
                </Link>
              )}
            </li>
          )}
          {permissions?.includes("LIST-ROLES") && (
            <li className="nav-li">
              <button
                className={
                  currentSubmenu === "role"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
                style={{ width: "47px" }}
              >
                <FontAwesomeIcon icon={faMoneyBill} />
              </button>
              {linkwidth && (
                <Link
                  type="role"
                  onClick={() => onClickSubmenuHandler("role")}
                  className={
                    currentSubmenu === "role" ? "nav-link active" : "nav-link "
                  }
                  to="/roles"
                >
                  Roles
                </Link>
              )}
            </li>
          )}
          {/* {permissions?.includes("LIST-ROLES") && (
            <li className="nav-li">
              <button
                className={
                  currentSubmenu === "role"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
                style={{ width: "47px" }}
              >
                <FontAwesomeIcon icon={faNoteSticky} />
              </button>
              {linkwidth && (
                <Link
                  type="role"
                  onClick={() => onClickSubmenuHandler("role")}
                  className={
                    currentSubmenu === "role" ? "nav-link active" : "nav-link "
                  }
                  to="/roles"
                  style={{
                    fontSize: "13px",
                    whiteSpace: "nowrap",
                    fontWeight: "bolder",
                  }}
                >
                  Terms and Conditions
                </Link>
              )}
            </li>
          )}
          {permissions?.includes("LIST-ROLES") && (
            <li className="nav-li">
              <button
                className={
                  currentSubmenu === "role"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
                style={{ width: "47px" }}
              >
                <FontAwesomeIcon icon={faDrawPolygon} />
              </button>
              {linkwidth && (
                <Link
                  type="role"
                  onClick={() => onClickSubmenuHandler("role")}
                  className={
                    currentSubmenu === "role" ? "nav-link active" : "nav-link "
                  }
                  to="/roles"
                >
                  Privacy Policy
                </Link>
              )}
            </li>
          )} */}
          {/* {permissions?.includes("broadcast") && ( */}
          {/* <li className="nav-li">
            <button
              style={{ width: "47px" }}
              className={
                currentSubmenu === "broadcast"
                  ? "toggle-nav-btn-active"
                  : "toggle-nav-btn"
              }
              onClick={onClickExpandHandler}
            >
              <BsBroadcastPin />
            </button>
            {linkwidth && (
              <Link
                type="broadcast"
                onClick={() => onClickSubmenuHandler("broadcast")}
                className={
                  currentSubmenu === "broadcast"
                    ? "nav-link active"
                    : "nav-link "
                }
                to="admin/broadcast/list"
              >
                BroadCast
              </Link>
            )}
          </li> */}
          {/* )} */}

          {permissions?.includes("LIST-ROLES") && (
            <li className="nav-li">
              <button
                className={
                  currentSubmenu === "department"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
                style={{ width: "47px" }}
              >
                <FontAwesomeIcon icon={faLayerGroup} />
              </button>
              {linkwidth && (
                <Link
                  type="department"
                  onClick={() => onClickSubmenuHandler("department")}
                  className={
                    currentSubmenu === "department"
                      ? "nav-link active"
                      : "nav-link "
                  }
                  to="/department/list"
                >
                  Department
                </Link>
              )}
            </li>
          )}

          <li className="nav-li">
            <button
              style={{ width: "47px" }}
              className={
                currentSubmenu === "supplier"
                  ? "toggle-nav-btn-active"
                  : "toggle-nav-btn"
              }
              onClick={onClickExpandHandler}
            >
              <FontAwesomeIcon icon={faUserShield} />
            </button>
            {linkwidth && (
              <Link
                type="dashboard"
                className={
                  currentSubmenu === "supplier"
                    ? "nav-link active"
                    : "nav-link "
                }
                onClick={() => setDropArrow(!dropArrow)}
              >
                Suppliers
                <FontAwesomeIcon icon={faArrowDown} />
              </Link>
            )}
          </li>

          {dropArrow && permissions?.includes("LIST-EXISTING SUPPLIER") && (
            <li className="nav-li">
              <button
                style={{ width: "47px" }}
                className={
                  currentSubmenu === "existing-supplier"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
              ></button>
              {linkwidth && (
                <Link
                  type="dashboard"
                  onClick={() => onClickSubmenuHandler("existing-supplier")}
                  className={
                    currentSubmenu === "existing-supplier"
                      ? "nav-link active"
                      : "nav-link "
                  }
                  to="/existing-supplier"
                >
                  Existing Supplier
                </Link>
              )}
            </li>
          )}
          {permissions?.includes("LIST-NEW SUPPLIER") && dropArrow && (
            <li className="nav-li">
              <button
                style={{ width: "47px" }}
                className={
                  currentSubmenu === "new-supplier"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}
              ></button>
              {linkwidth && (
                <Link
                  type="dashboard"
                  onClick={() => onClickSubmenuHandler("new-supplier")}
                  className={
                    currentSubmenu === "new-supplier"
                      ? "nav-link active"
                      : "nav-link "
                  }
                  to="/new-supplier"
                >
                  New Supplier
                </Link>
              )}
            </li>
          )}

          {/* {permissions?.includes("content management") && (
            <li className="nav-li">
              <button
                style={{ width: "47px" }}
                className={
                  currentSubmenu === "blogs"
                    ? "toggle-nav-btn-active"
                    : "toggle-nav-btn"
                }
                onClick={onClickExpandHandler}>
                <FontAwesomeIcon icon={faBlog} />
              </button>
              {linkwidth && (
                <Link
                  type="dashboard"
                  onClick={() => onClickSubmenuHandler("blogs")}
                  className={
                    currentSubmenu === "blog" ? "nav-link active" : "nav-link "
                  }
                  to="blogs">
                  Blogs
                </Link>
              )}
            </li>
          )} */}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
