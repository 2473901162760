import React, { useState, useEffect } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import TopBar from "../../../navbar/TopBar";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopHeader from "../../../navbar/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../../constants/Services";
import eye_icon from "../../../assets/Icons/Normal Icons/Eye.svg";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import { Check, FavLogo } from "../../../assets/img";
import GeneralFilter from "../../../component/GeneralFilter";
import edit_icon from "../../../assets/Icons/Normal Icons/Edit.svg";
import GeneralPagination from "../../../component/GeneralPagination";
import {
  existing_suppliers_list_redux_store,
  existing_supplier_detail,
} from "../../../constants/constants/Storage/actions";
import ErrorModel from "../../../component/errorModel";
import ReactPaginate from "react-paginate";

function ExistingSupplier() {
  const [invited, setInvited] = useState([]);
  const permissions = localStorage.getItem("permission");
  const [viewModal, setViewModal] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [inviteApproved, setInviteApproved] = useState([]);
  const [modalError, setModalError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [activePages, setActivePages] = useState();
  const [count, setCount] = useState(0);
  const [factoriesList, setFactoriesList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [checkeds, setCheckeds] = useState([]);
  const [btn, setBtn] = useState(false);
  const dispatch = useDispatch();
  const existing_supplier_list = useSelector(
    (state) => state.existing_supplier_list_show
  );
  // const login = useSelector((state) => state.login_detail);

  // console.log("exis-redux", existing_supplier_list);

  useEffect(() => {
    let temp = [];
    api.get_factories().then((res) => {
      if (res?.status == "success") {
        let responce = res?.factories?.filter((i) => i?.id !== 1);
        if (responce) {
          responce?.map((item) => {
            temp.push(item);
          });
          setFactoriesList(temp);

        }
        // setFactoriesList(temp);
        // console.log(factoriesList,'factoriesList');
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  }, []);

  const viewExistingSupplier = (sup_id) => {
    setViewModal(!viewModal);
    // api.view_existing_supplier(sup_id).then((res) => {
    //   console.log("supplier-view-existing", res);
    //   dispatch(existing_supplier_detail(res?.supplier));
    // });
  };

  const checkBoxHandler = (id) => {
    let checkboxs = [...checked];

    if (checkboxs.includes(id)) {
      let ids = checkboxs.indexOf(id);
      checkboxs.splice(ids, 1);
      setChecked(checkboxs);
    } else {
      checkboxs.push(id);
      setChecked(checkboxs);
    }
  };

  // console.log("checked", checked);

  const getExistingSuppliers = () => {
    let params;

    if (search !== "") {
      params = `?page=${page}&supplier_name=${search}`;
    } else {
      params = `?page=${pages}`;
    }

    api.get_existing_suppliers(params).then((res) => {
      // console.log("existing-suopplier-list", res);
      let temp = [];
      if (res?.status === "success") {
        setList(res?.existing_suppliers);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setActivePages(res?.pagination);
        let inviteSend = res?.existing_suppliers?.filter(
          (i) => i?.invited == 1 && +i?.register_status == 1
        );
        let inviteSends = res?.existing_suppliers?.filter(
          (i) => i?.invited == 2
        );
        if (inviteSend) {
          inviteSend?.map((item, ind) => {
            temp.push(item?.SupplierID);
          });
        }

        if (inviteSends) {
          inviteSends?.map((item, ind) => {
            temp.push(item?.SupplierID);
          });
        }

        setInviteApproved(temp);
        // console.log("inviteSend", temp);
        dispatch(existing_suppliers_list_redux_store(res?.existing_suppliers));
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    if (search !== "") {
      setPage(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
    }

    setCount(selectedObject.selected);
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    getExistingSuppliers();
  }, [pages, search, page]);

  const inviteHandler = () => {
    let data = {
      supplier_ids: invited,
    };

    api.invite_supplier(data).then((res) => {
      if (res?.status === "success") {
        // setInviteApproved(invited);
        // console.log("invite", res);
        getExistingSuppliers();
        setShow(true);
        setInvited([]);
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };

  const [editData, setEditData] = useState({});

  const handleOpen = (data) => {
    let temp = [];
    setEditData(data);
    // temp.push(+data?.FactoryList);
    const factoryList = data?.FactoryList;
    const newFactoryList = factoryList.split(",").map(Number);
    temp.push(newFactoryList);
    // setChecked(temp);
    // setCheckeds(temp);
    setChecked(newFactoryList);
    setCheckeds(newFactoryList);
    console.log(checked,'checked');

    setOpen(true);
  };

  const supplierStatusHandler = () => {
    let formdata = new FormData();
    // console.log(editData, 'editData');
    formdata.append("supplier_id", editData?.SupplierID);
    formdata.append("status", 4);
    formdata.append("email", editData?.Email);

    checked?.map((item, ind) => {
      if (!checkeds?.includes(item)) {
        formdata.append(`factories[${ind}]`, item);
      }
    });

    api.factoriesChange(formdata).then((res) => {
      if (res?.status == "success") {
        setOpen(false);
        getExistingSuppliers();
      } else if (res?.response?.data?.message.startsWith("SQLSTATE[23000]:")) {
        setModalError(res);
        setErrorBox(true);
        setTimeout(() => {
          setOpen(false);
        }, 1500);
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };

  const invitedSelHandler = (inv_id) => {
    if (invited.includes(inv_id)) {
      let filteredList = invited.filter((item) => item !== inv_id);
      setInvited(filteredList);
    } else {
      setInvited((invited) => [...invited, +inv_id]);
    }
  };
  return (
    <>
      invited
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={modalError}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="existing-supplier" />
      <div style={{ minHeight: "75vh" }} className="w-90 px-2 py-3 my-2">
        <button
          style={{ height: "35px" }}
          disabled={invited.length === 0 ? true : false}
          className="bg-primary1 bg-primary1 fnt-fam-f2 px-3 py-1 text-white rounded-1 border-0 float-end ms-4"
          onClick={inviteHandler}
        >
          Invite
        </button>
        <div className="d-flex flex-wrap my-2 my-md-0 align-items-center">
          <div className="action-page-cont">
            <p>Showing</p>
            <div className="page-status-cont">
              <p
                style={{
                  border: "1px solid #ced4da",
                  padding: "4px",
                  // margin: "10px 0px",
                  textAlign: "center",
                  width: "35px",
                  height: "28px",
                }}
              >
                {list?.length}
              </p>
            </div>
            <p>of {activePages?.total} Suppliers</p>
          </div>
          <Col xs={12} lg={4} md={8} className="d-flex my-1 ms-auto my-md-0">
            <div className="search-box d-flex justify-content-between align-items-center px-2 w-100 rounded-1">
              <input
                placeholder="Search Title"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <img className="search-icon" src={Search} alt="searBar" />
            </div>
          </Col>
        </div>
        <Col className="prduct-page">
          <div
            className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll "
            style={{ overflowX: "auto" }}
          >
            <table className="w-100">
              <thead>
                <tr className="text-center">
                  <th className="">Invite</th>
                  <th className="">S.No</th>
                  <th className="text-nowrap">Company ID</th>
                  <th className="text-nowrap">Factory ID</th>
                  <th className="text-nowrap">Company Name</th>
                  {/* <th className="">Company Name</th> */}
                  <th className="">Category</th>
                  <th className="">EMail</th>
                  <th className="">GST No.</th>
                  <th className="text-nowrap">MSME Code/IE Code</th>
                  <th className="">Status</th>
                  <th className="">Registered</th>
                  <th className="">Action</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, ind) => {
                  let factoriesName = factoriesList?.find(
                    (i) => i?.id === +item?.FactoryList
                  );
                  return (
                    <>
                      <tr key={ind} className="shipment-table text-center">
                        <td className="">
                          {!inviteApproved.includes(item?.SupplierID) && (
                            <input
                              type="checkbox"
                              onChange={() =>
                                invitedSelHandler(+item?.SupplierID)
                              }
                            />
                          )}
                        </td>
                        <td className="">
                          {ind + 1 == 10 ? count + 1 : count}
                          {ind + 1 == 10 ? 0 : ind + 1}
                        </td>
                        <td className="">{item?.SupplierID}</td>
                        <td className="">{item?.FactoryList}</td>

                        <td className="">{item?.SupplierName}</td>
                        {/* <td className="">{factoriesName?.name}</td> */}
                        <td className="">
                          {item?.SupplierCategory?.startsWith("M&L")
                            ? "Material & Leather"
                            : item?.SupplierCategory?.startsWith("M")
                            ? "Material"
                            : item?.SupplierCategory?.startsWith("L")
                            ? "Leather"
                            : ""}
                        </td>
                        <td className="text-nowrap">{item?.Email ?? "-"}</td>
                        <td className="text-center">
                          {item?.GSTNo
                            ? item?.GSTNo == "null"
                              ? "-"
                              : item?.GSTNo
                            : "-"}
                        </td>
                        <td className="">
                          {item?.MSMENo
                            ? item?.MSMENo == "null"
                              ? "-"
                              : item?.MSMENo
                            : "-"}
                        </td>

                        {/* <td className="img_tick">
                        {inviteApproved.includes(item?.SupplierID) && (
                          <img src={Check} alt="tickImage" />
                        )}
                      </td> */}
                        <td className="img_tick text-nowrap">
                          {item?.invited == 1
                            ? // <img src={Check} alt="tickImage" />
                              "Invited"
                            : item?.invited == 0
                            ? "Yet To Invite"
                            : "Approved"}
                        </td>
                        <td className="img_tick">
                          {" "}
                          {item?.register_status == 1 && (
                            <img src={Check} alt="tickImage" />
                          )}
                        </td>
                        <td className="pointer action-box">
                          <img
                            src={edit_icon}
                            alt="edit-icon"
                            onClick={() => handleOpen(item)}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
        <div className="page-inner-cont d-flex justify-content-end mt-3">
          <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="‹"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton className="border-0 pb-0">
          <img style={{ width: "41px" }} src={FavLogo} alt="logo" />
        </Modal.Header>
        <Modal.Body className="d-flex gap-3 mb-5 justify-content-center">
          <label className="fnt-fam-f2 fs-6  text-center">
            Invite has been sucessfully send
          </label>
          <img style={{ width: "32px" }} src={Check} alt="tickImage" />
        </Modal.Body>
      </Modal>
      <Modal
        show={viewModal}
        backdrop="static"
        onHide={() => setViewModal(false)}
        centered
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <img style={{ width: "41px" }} src={FavLogo} alt="logo" />
        </Modal.Header>
        <Modal.Body>
          <Col md={7} xs={12} className="d-flex flex-wrap mx-auto">
            <Col xs={12} md={7} lg={6}>
              <label className="fnt-fam-f2 fs-6  text-center">Invited</label>
              <p>10.04.2023</p>
            </Col>
            <Col xs={12} md={7} lg={6}>
              <label className="fnt-fam-f2 fs-6  text-center">Registered</label>
              <p>test</p>
            </Col>
            <Col xs={12} md={7} lg={6}>
              <label className="fnt-fam-f2 fs-6  text-center">Active</label>
              <p>10.04.2022</p>
            </Col>
            <Col xs={12} md={7} lg={6}>
              <label className="fnt-fam-f2 fs-6  text-center">In Active</label>
              <p>20.01.2022</p>
            </Col>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={open} onHide={() => setOpen(false)} centered>
        <Modal.Header
          closeButton
          className="border-0 text-center"
        ></Modal.Header>
        <Modal.Body className="py-1">
          <h5 className="w-100 text-center">
            Choose Factories you want to Approve
          </h5>
          <div className="d-flex flex-column align-items-center my-4">
            {factoriesList?.map((factory) => (
              <div className="d-flex w-50 justify-content-between">
                <label>{factory?.code}</label>
                <input
                  value={factory?.id}
                  type="checkbox"
                  onChange={(e) => checkBoxHandler(factory?.id)}
                  checked={checked?.includes(factory?.id)}
                  disabled={checkeds?.includes(factory?.id)}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
              setChecked([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => supplierStatusHandler()}
            disabled={btn ? true : false}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExistingSupplier;
